<template>
    <vx-card title="Get Sales's Unloading Items">
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <div class="mb-6 vx-row pt-2">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <span>Sales Work ID Number</span>
                    </div>
                    <div class="w-full vx-col sm:w-2/3">
                        <vs-input
                            type="text"
                            v-model="work_id_number"
                            class="w-full"
                        />
                    </div>
                </div>
            </div>
            <div class="w-full md:basis-1/2">
                <div class="w-full pr-6 md:basis-1/2">
                    <!-- ... -->
                </div>
            </div>
        </div>
        <div class="vx-col sm:w-1/1 w-full" style="margin-top: 50px;">
            <div style="
                position: absolute;
                left: 50%;
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            ">
                <vs-button class="mr-3 mb-2" @click="handleGetItems()">Get Items</vs-button>
            </div>
        </div>
        <br>
    </vx-card>
</template>

<script>
import vSelect from "vue-select";

export default {
    components: {
        vSelect,
    },
    data() {
        return {
            work_id_number: "",
        }
    },
    mounted() {
        // ...
    },
    methods: {
        handleGetItems() {
            if (this.work_id_number == "") {
                this.$vs.dialog({
                    color: "danger",
                    title: "Blank work ID number",
                    text: "Please fill sales's work ID number",
                });
                return false;
            }

            let params = {
                work_id_number: this.work_id_number,
            }

            this.$vs.loading();
            this.$http.post("/api/wms/v1/canvas/unloading/unloading-item-stock", params)
            .then(resp => {
                if (resp.code == 200) {
                    this.$vs.loading.close();
                    this.$vs.dialog({
                        color: "success",
                        title: "Get data success",
                        text: resp.message,
                    })
                } else {
                    this.$vs.loading.close();
                    this.$vs.dialog({
                        color: "danger",
                        title: "Something went wrong",
                        text: resp.message,
                    })
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
    }
}
</script>